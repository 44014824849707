/* eslint-disable react/prop-types */
import 'moment-timezone';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactMoment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { items } from '../utils';
import { withLayout } from '../components/Layout';
import SEO from '../components/SEO';
import ThumbnailItem from '../components/ThumbnailItem';
import ShareButtons from '../components/ShareButtons';

const useStyles = makeStyles((theme) => ({
  cover: (series) => ({
    color: theme.palette.text.white,
    textShadow: '1px 1px 10px rgba(0, 0, 0, 0.25)',
    background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${series.imageCover})`,
    backgroundSize: 'cover, cover',
    backgroundPosition: 'center, center',
    padding: `${theme.spacing(24)}px ${theme.spacing(4)}px`,
    marginTop: theme.spacing(-6),
    marginBottom: theme.spacing(4),
    marginLeft: -1 * theme.spacing(2),
    marginRight: -1 * theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -1 * theme.spacing(3),
      marginRight: -1 * theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-2),
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: theme.spacing(8),
    },
  }),
}));

const Page = ({ pageContext }) => {
  const { series } = pageContext;

  // group individual series by date
  const sermonDates = series
    ? items(series.sermons).reduce((arr, sermon) => {
        const dateGroup = arr.findIndex((group) => group[0] === sermon.date);
        if (dateGroup > -1) {
          arr[dateGroup][1].push(sermon);
        } else {
          arr.push([sermon.date, [sermon]]);
        }
        return arr;
      }, [])
    : [];

  const classes = useStyles(series);

  return (
    <>
      <SEO
        title={series.title}
        cardType="summary_large_image"
        image={series.imageUnfurl}
        description={`${moment(series.startDate).format('MMMM Do')} — ${moment(
          series.endDate
        ).format(
          'MMMM Do, YYYY'
        )} at River of Life Christian Fellowship in Loveland, Colorado.`}
      />

      <Box className={classes.cover}>
        <Typography variant="h1">{series.title}</Typography>
        <Typography variant="subtitle1">
          <ReactMoment tz="US/Mountain" format="MMMM D">
            {series.startDate}
          </ReactMoment>{' '}
          &mdash;{' '}
          <ReactMoment tz="US/Mountain" format="MMMM D">
            {series.endDate}
          </ReactMoment>
          {', '}
          <ReactMoment tz="US/Mountain" format="YYYY">
            {series.endDate}
          </ReactMoment>
        </Typography>
      </Box>

      <Box mb={series.youtubePlaylistId ? 2 : 6}>
        {sermonDates.map(([date, sermons]) => (
          <Box key={date} mb={2}>
            <Box mb={2}>
              <Typography variant="h5">
                <ReactMoment format="MMMM D, YYYY">{date}</ReactMoment>
              </Typography>
            </Box>
            {sermons.map((sermon) => (
              <ThumbnailItem
                key={sermon.id}
                title={sermon.title}
                subtext={
                  sermon.speakers &&
                  sermon.speakers.edges.map(({ node }) => node.name).join(', ')
                }
                timestamp={sermon.date}
                image={`https://i.ytimg.com/vi/${sermon.youtubeId}/hqdefault.jpg`}
                to={`/media/sermons/${sermon.slug}`}
              />
            ))}
          </Box>
        ))}
      </Box>

      {series.youtubePlaylistId && (
        <Box mb={6}>
          <Button
            href={`https://www.youtube.com/playlist?list=${series.youtubePlaylistId}`}
            color="secondary"
            endIcon={<ChevronRightIcon />}
          >
            Watch on YouTube
          </Button>
        </Box>
      )}

      <ShareButtons title={series.title} />
    </>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default withLayout(Page, { container: 'md' });
